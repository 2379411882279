import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';

const ConnectMarketersPage = lazy(() => import('src/pages/connect-marketers/view'));

export const connectMarketersRoutes = [
  {
    path: 'connect-marketers',
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [{ element: <ConnectMarketersPage />, index: true }],
  },
];
